import React from 'react';
import { GridTableCell } from '../../../../../../shared/components/table-components';
import { getPriceView } from '../../../../../../shared/utils/stringUtils';

type PriceCellProps = {
  price: number;
};

const PriceCell = ({ price }: PriceCellProps) => {
  return <GridTableCell className="private-info">{getPriceView(price)}</GridTableCell>;
};

export { PriceCell, PriceCellProps };
