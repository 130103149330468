import React, { useEffect, useState } from 'react';

import classes from './MealStatisticWidget.module.scss';
import { InfoBox } from '../../../../../../shared/components/ui-components';
import { MealDetailsRow } from './MealDetailsRow/MealDetailsRow';
import { SummaryInfo } from './SummaryInfo/SummaryInfo';
import { MealStatistic } from '../../../../../interfaces/performance-metrics/MealStatistic';
import { defaultMealStatistic, mealsList } from './MealStatisticWidget.settings';
import { getPercentages } from '../../../../../../shared/utils/mathUtils';
import { PerformanceMetricsParams } from '../../../../../interfaces/performance-metrics/PerformanceMetricsParams';
import { performanceMetricsService } from '../../../../../services/performanceMetricsService';

type MealStatisticWidgetProps = {
  customerId: number;
  params: PerformanceMetricsParams;
};

const MealStatisticWidget = ({ customerId, params }: MealStatisticWidgetProps) => {
  const [mealStatistic, setMealStatistic] = useState<MealStatistic>(defaultMealStatistic);
  const { mealsTotal, mealsTracked } = mealStatistic;

  const getMealStatistic = async () => {
    const data = await performanceMetricsService.getMealStatistic(customerId, params);
    setMealStatistic(data);
  };

  useEffect(() => {
    getMealStatistic();
  }, [customerId, params]);

  const trackedMealsPercent = getPercentages(mealsTracked, mealsTotal, 0);
  const skippedMeals = mealsTotal - mealsTracked;
  const skippedMealsPercent = getPercentages(skippedMeals, mealsTotal, 0);

  return (
    <InfoBox title="Meals">
      <div className={classes.summaryRow}>
        <SummaryInfo title="Total Meals:" value={mealStatistic.mealsTotal} />
        <SummaryInfo
          title="Tracked Meals:"
          total={mealStatistic.mealsTracked}
          progressValue={trackedMealsPercent}
          dataTestId="tracked-meals"
        />
        <SummaryInfo
          title="Not Entered/Skipped:"
          total={skippedMeals}
          progressValue={skippedMealsPercent}
          dataTestId="skipped-meals"
        />
      </div>
      <div className={classes.mealDetails}>
        {mealsList.map((meal) => (
          <MealDetailsRow key={meal} meal={meal} mealInfo={mealStatistic[meal]} />
        ))}
      </div>
    </InfoBox>
  );
};

export { MealStatisticWidget };
