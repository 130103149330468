import React from 'react';

import classNames from 'classnames';

import classes from './AnswerItem.module.scss';

type AnswerItemProps = {
  questionNumber: number;
  question: string;
  answer?: string;
};

const AnswerItem = ({ questionNumber, question, answer }: AnswerItemProps) => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>{questionNumber}.&nbsp;</p>
      <div>
        <p className={classes.title}>{question}</p>
        <p className={classNames(classes.answer, 'private-info')}>
          {answer || 'No text'}
        </p>
      </div>
    </div>
  );
};

export { AnswerItem };
