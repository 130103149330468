import React from 'react';

import { Path, UseControllerProps, useController } from 'react-hook-form';

import { InputView } from '../../../../../../shared/components/form-components';
import { SubscriptionSettingsFormValues } from '../../../../../interfaces/subscriptionSettings';
import { getIsNullish } from '../../../../../../shared/utils/jsUtils';

type ViewSubscriptionInputProps = {
  label: string;
  helperText?: string;
  prefix?: string;
  suffix?: string;
} & Pick<
  UseControllerProps<
    SubscriptionSettingsFormValues,
    Path<SubscriptionSettingsFormValues>
  >,
  'control' | 'name'
>;

const ViewSubscriptionInput = ({
  control,
  name,
  label,
  helperText,
  prefix = '',
  suffix = '',
}: ViewSubscriptionInputProps) => {
  const {
    field: { value },
  } = useController({ control, name });

  const inputValue = getIsNullish(value) ? 'Not set' : `${prefix}${value}${suffix}`;

  return (
    <InputView
      label={label}
      value={inputValue}
      helperText={helperText}
      valueClassName="private-info"
    />
  );
};

export { ViewSubscriptionInput, ViewSubscriptionInputProps };
