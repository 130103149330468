import React, { ReactNode } from 'react';

import { Button } from '@mui/material';

import classes from './UserInfoCell.module.scss';

type UserInfoCellProps = {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  dataTestId?: string;
  href: string;
  disabled?: boolean;
  nameAdornment?: ReactNode;
};

const UserInfoCell = ({
  firstName,
  lastName,
  email,
  phone,
  dataTestId,
  href,
  disabled,
  nameAdornment,
}: UserInfoCellProps) => {
  return (
    <Button
      variant="link"
      disabled={disabled}
      href={href}
      disableRipple
      className={classes.userInfo}
      data-test-id={dataTestId}
    >
      <div className={classes.nameRow}>
        <h6 className="private-info">
          <span>{firstName}</span>
          &nbsp;
          <span>{lastName}</span>
        </h6>
        {nameAdornment}
      </div>
      {email && <p className="private-info">{email}</p>}
      {phone && <p className="private-info">{phone}</p>}
    </Button>
  );
};

export { UserInfoCell };
