import React from 'react';
import PropTypes from 'prop-types';

import {
  EditDeleteButtonGroup,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';

const CorporateAccountsTableRow = ({ account, openModal, deleteAccount }) => {
  const { id, login, password } = account;
  const hiddenPassword = password.replace(/./g, '●');

  const handleDelete = () => {
    deleteAccount(id);
  };

  const handleOpenEditModal = (accountData) => () => {
    openModal(accountData, id);
  };

  return (
    <GridTableRow variant="row" key={id}>
      <span className="private-info">{login}</span>
      <span>{hiddenPassword}</span>
      <EditDeleteButtonGroup
        onEdit={handleOpenEditModal({ login, password })}
        onDelete={handleDelete}
        modalTitle="Are you sure you want to delete corporate account?"
      />
    </GridTableRow>
  );
};

CorporateAccountsTableRow.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    login: PropTypes.string,
    password: PropTypes.string,
  }),
  openModal: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

export { CorporateAccountsTableRow };
