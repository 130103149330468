import React from 'react';

import classes from './MealRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../../shared/components/table-components';
import { NutrientMetricsForMeal } from '../../../../../../../interfaces/performance-metrics/NutrientMetrics';

type MealRowProps = {
  title: string;
  mealInfo: NutrientMetricsForMeal;
};

const MealRow = ({ title, mealInfo }: MealRowProps) => {
  const { fiber, sodium, sugar, veggies, calories } = mealInfo;

  return (
    <GridTableRow variant="subRow" data-test-id="nutrient-meals-row">
      <GridTableCell variant="title">{title}</GridTableCell>
      <GridTableCell className={classes.fiberCell}>
        {fiber ? (
          <>
            <span className={classes.value}>{fiber.avgPerMeal}</span>
            <span className={classes.description}>
              ({fiber.completedMeals}/{fiber.totalMeals})&nbsp;—&nbsp;
              {fiber.completedMealsPercent}%
            </span>
          </>
        ) : (
          '—'
        )}
      </GridTableCell>
      <GridTableCell>{sodium ? sodium.avgPerMeal : '—'}</GridTableCell>
      <GridTableCell>{sugar ? sugar.avgPerMeal : '—'}</GridTableCell>
      <GridTableCell>{veggies ? veggies.avgPerMeal : '—'}</GridTableCell>
      <GridTableCell>{calories ? calories.avgPerMeal : '—'}</GridTableCell>
    </GridTableRow>
  );
};

export { MealRow };
