import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import classes from './NgzQuestionnaiteTab.module.scss';
import { InfoBox } from '../../../../../shared/components/ui-components';
import { AnswerItem } from './AnswerItem/AnswerItem';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../shared/constants/apiDateFormat';
import { questionnaireReplyStore } from '../../../../stores/questionnaireReplyStore';

const NgzQuestionnaireTab = observer(() => {
  const customerId = getRouteParam('id');

  const { createdOn, questionReply, getCustomerQuestionnaire, setDefaultValues } =
    questionnaireReplyStore;

  useEffect(() => {
    getCustomerQuestionnaire(customerId);

    return () => {
      setDefaultValues();
    };
  }, [customerId]);

  return (
    <InfoBox
      title="Questionnaire"
      customContentPadding
      icon={
        <p className={classNames(classes.createdAt, 'private-info')}>
          Created: {getFormattedDate(createdOn, localDataFormat)}
        </p>
      }
    >
      {questionReply.map((reply, index) => (
        <AnswerItem
          key={reply.questionId}
          questionNumber={index + 1}
          question={reply.name || ''}
          answer={reply.answer.name}
        />
      ))}
    </InfoBox>
  );
});

angularize(
  NgzQuestionnaireTab,
  'ngzQuestionnaireTab',
  angular.module('app.customerManagement'),
  {},
);
