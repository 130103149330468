import React from 'react';

import { observer } from 'mobx-react';
import { Switch } from '@mui/material';

import classes from './PrivateModeSwitch.module.scss';
import { loggedInUserDataStore } from '../../../../../stores/loggedInUserDataStore';
import { LbFormControlLabel } from '../../../../../components/form-components';
import { privateModeStore } from '../../../../../features/private-mode';

type PrivateModeItemProps = {
  onClick: () => void;
};

const PrivateModeSwitch = observer(({ onClick }: PrivateModeItemProps) => {
  const { isEntity, isCoach, isSuperAdmin } = loggedInUserDataStore;
  const { isPrivateMode, handleModeChange } = privateModeStore;

  const isShow = isEntity || isCoach || isSuperAdmin;

  if (!isShow) {
    return null;
  }

  return (
    <LbFormControlLabel
      control={<Switch />}
      checked={isPrivateMode}
      onChange={(event, checked) => {
        handleModeChange(checked);
        onClick();
      }}
      label="Private mode"
      labelPlacement="start"
      className={classes.privateModeSwitch}
    />
  );
});

export { PrivateModeSwitch };
