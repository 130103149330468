import { makeAutoObservable } from 'mobx';
import { LOCAL_STORAGE_KEYS } from '../constants/LOCAL_STORAGE_KEYS';

class PrivateModeStore {
  isPrivateMode: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  handleModeChange(isPrivateMode: boolean) {
    this.setIsPrivateMode(isPrivateMode);

    localStorage.setItem(LOCAL_STORAGE_KEYS.PRIVATE_MODE, JSON.stringify(isPrivateMode));
    document.body.classList.toggle('private-mode', isPrivateMode);
  }

  initPrivateMode() {
    const privateMode = localStorage.getItem(LOCAL_STORAGE_KEYS.PRIVATE_MODE);

    if (privateMode) {
      const isPrivateMode = JSON.parse(privateMode);

      this.handleModeChange(isPrivateMode);
    } else {
      this.handleModeChange(false);
    }
  }

  private setIsPrivateMode(isPrivateMode: boolean) {
    this.isPrivateMode = isPrivateMode;
  }
}

const privateModeStore = new PrivateModeStore();

export { privateModeStore, PrivateModeStore };
