import React from 'react';

import { Button } from '@mui/material';
import { useToggle, useUnmount } from 'react-use';
import { observer } from 'mobx-react';

import classes from './TaxWidget.module.scss';
import { InfoBox, LbChip } from '../../../shared/components/ui-components';
import { SetUpTaxModal } from './SetUpTaxModal/SetUpTaxModal';
import { ActivationStatusChip } from '../../components/ActivationStatusChip/ActivationStatusChip';
import { taxStore } from '../../stores/taxStore';
import { useGetNecessaryData } from '../../../shared/hooks/useGetNecessaryData';

type TaxWidgetProps = {
  entityId: number;
};

const TaxWidget = observer(({ entityId }: TaxWidgetProps) => {
  const [isShowSetTaxModal, toggleShowSetTaxModal] = useToggle(false);
  const { appliedTaxRateType, entityTaxRate, setTaxRate, getTaxInfo, setDefaultValues } =
    taxStore;

  useGetNecessaryData(async () => {
    await getTaxInfo(entityId);
  });
  useUnmount(setDefaultValues);

  const isTaxSet = entityTaxRate?.id;
  const CompleteSetupChip = <LbChip label="Complete setup" size="small" color="error" />;

  return (
    <>
      <InfoBox title="Entity Tax" icon={!isTaxSet && CompleteSetupChip}>
        <div className={classes.textBox}>
          <div className={classes.titleBox}>
            <h5 className={classes.title}>
              {isTaxSet ? (
                <>
                  <span>Sales Tax:</span>
                  <span className="private-info">{entityTaxRate.taxRatePercent}%</span>
                </>
              ) : (
                'No Sales Tax'
              )}
            </h5>
            <ActivationStatusChip active={appliedTaxRateType.ENTITY_TAX_RATE} />
          </div>
          <p className={classes.description}>
            Set up the combined SaaS tax, and sales tax percentages, based on the
            Entity&apos;s State/City taxes.
            <br />
            Upon tax setup, the subscription amount will be adjusted to reflect the
            applicable tax.
          </p>
          <p className={classes.description}>
            Total Subscription Price = Subscription Price + Entity Tax, %
          </p>
        </div>
        <Button variant="outlined" size="small" onClick={toggleShowSetTaxModal}>
          Set up tax
        </Button>
      </InfoBox>
      {isShowSetTaxModal && (
        <SetUpTaxModal onClose={toggleShowSetTaxModal} onSubmit={setTaxRate} />
      )}
    </>
  );
});

export { TaxWidget };
