import React, { Fragment } from 'react';

import classNames from 'classnames';

import classes from './SummaryDetails.module.scss';
import { TotalAmountChartConfigItem } from '../TotalAmountChart.settings';
import { getPriceView } from '../../../../../../shared/utils/stringUtils';
import { InfoTooltip } from '../../../../../../shared/components/ui-components';

type SummaryDetailsProps = {
  config: TotalAmountChartConfigItem[];
};

// if table becomes too big create separate row component
const SummaryDetails = ({ config }: SummaryDetailsProps) => {
  return (
    <div className={classes.table}>
      {config.map((item) => (
        <Fragment key={item.dbName}>
          <div className={classes.nameCell}>
            <div className={classes.rectangle} style={{ backgroundColor: item.color }} />
            <span>{item.title}</span>
            {item.description && (
              <InfoTooltip iconFontSize="small" placement="right">
                {item.description}
              </InfoTooltip>
            )}
          </div>
          <div className={classNames(classes.amountCell, 'private-info')}>
            {getPriceView(item.amount)}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export { SummaryDetails };
