import React, { useRef } from 'react';

import Button from '@mui/material/Button';
import { KeyboardArrowDown } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import classes from './UserMenu.module.scss';
import { useModalToggle } from '../../../hooks/useModalToggle';
import { AvatarIcon } from '../../../components/ui-components';
import { loggedInUserDataStore } from '../../../stores/loggedInUserDataStore';
import { getHumanizeUserRole } from '../../../utils/userInfoUtils';
import {
  LogoutItem,
  ReleasesItem,
  ProfileItem,
  BackToHomeItem,
  GoHighLevelRefreshItem,
  PrivateModeSwitch,
} from './items';
import { getUserName } from './utils/getUserName';

const UserMenu = observer(() => {
  const ref = useRef();
  const [isMenuOpen, toggleMenuOpen] = useModalToggle();

  const { loggedInUser } = loggedInUserDataStore;
  const { avatar, role } = loggedInUser;

  const userName = getUserName(loggedInUser);

  return (
    <div>
      <Button
        ref={ref}
        variant="unstyled"
        onClick={toggleMenuOpen}
        startIcon={
          <AvatarIcon
            userName={userName}
            className="private-info"
            avatarImgLink={avatar}
          />
        }
        endIcon={<KeyboardArrowDown />}
        disableRipple
      >
        <div className={classNames(classes.userInfoBox, 'private-info')}>
          <p className={classes.userName}>{userName}</p>
          <p className={classes.userRole}>{getHumanizeUserRole(role)}</p>
        </div>
      </Button>
      <Menu
        anchorEl={ref.current}
        open={isMenuOpen}
        onClick={toggleMenuOpen}
        variant="menu"
      >
        <PrivateModeSwitch onClick={toggleMenuOpen} />
        <ProfileItem />
        <ReleasesItem />
        <GoHighLevelRefreshItem />
        <BackToHomeItem />
        <LogoutItem />
      </Menu>
    </div>
  );
});

export { UserMenu };
