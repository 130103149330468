import React from 'react';

import { Paper } from '@mui/material';

import classes from './NutrientInfoCard.module.scss';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { getEnumArray } from '../../../../../../../shared/utils/jsUtils';
import {
  REGULAR_MEAL_NAME,
  RegularMealName,
} from '../../../../../../../shared/constants/mealNames';
import { MealInfoRow } from './MealInfoRow/MealInfoRow';
import { LbCircularProgress } from '../../../../../../../shared/components/ui-components';
import { MainNutrientMealsInfo } from '../../../../../../interfaces/performance-metrics/NutrientMetrics';

const mealsList: RegularMealName[] = getEnumArray(REGULAR_MEAL_NAME);

type NutrientInfoCardProps = {
  nutrient: string;
  mealsInfo: MainNutrientMealsInfo;
  dataTestId: string;
};

const NutrientInfoCard = ({ nutrient, mealsInfo, dataTestId }: NutrientInfoCardProps) => {
  const { avgOnMealsTracked } = mealsInfo;

  return (
    <Paper variant="boxWithPadding" className={classes.box} data-test-id={dataTestId}>
      <TitleWithDescription
        title={nutrient}
        description="Average % of goal tracked meals"
        titleFont="body-14-bold"
        withoutGap
      />
      <div className={classes.infoBox}>
        <LbCircularProgress
          value={avgOnMealsTracked?.avgTrackedPercent || 0}
          size={124}
          className={classes.circle}
        >
          {avgOnMealsTracked ? (
            <>
              <span className={classes.value} data-test-id="avg-value">
                {avgOnMealsTracked.avgTrackedPercent}
              </span>
              <span className={classes.percent}>%</span>
            </>
          ) : (
            <span className={classes.value}>—</span>
          )}
        </LbCircularProgress>
        <div className={classes.mealsTable}>
          {mealsList.map((meal) => (
            <MealInfoRow key={meal} meal={meal} mealInfo={mealsInfo[meal]} />
          ))}
        </div>
      </div>
    </Paper>
  );
};

export { NutrientInfoCard };
