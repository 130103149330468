import React from 'react';
import PropTypes from 'prop-types';

import classes from './MealTableTitle.module.scss';
import {
  SeparatorLine,
  CheckCircleLabel,
} from '../../../../shared/components/ui-components';
import { ScoreBlock } from './ScoreBlock/ScoreBlock';
import { OLD_MEAL_NAMES } from '../../../../shared/constants/mealNames';
import { processMealName } from '../../../../shared/utils/mealUtils';

const MealTableTitle = ({
  mealName,
  userName,
  scoreValue,
  isReport,
  isModal,
  isSkipped,
}) => {
  const isWorkoutMeal =
    mealName === OLD_MEAL_NAMES.POST_WORKOUT || mealName === OLD_MEAL_NAMES.PRE_WORKOUT;
  const meal = processMealName(mealName);

  return (
    <div className={classes.container} data-test-id="meal-table-title">
      <div className={classes.mealInfo}>
        {isReport && <span>{meal}</span>}
        {isModal && (
          <>
            <span className="private-info">{userName}</span>
            <SeparatorLine />
            <span>{meal}</span>
          </>
        )}
      </div>
      {isSkipped && <CheckCircleLabel color="error">Skipped</CheckCircleLabel>}
      {!isWorkoutMeal && !isSkipped && <ScoreBlock score={scoreValue} />}
    </div>
  );
};

MealTableTitle.propTypes = {
  mealName: PropTypes.string,
  userName: PropTypes.string,
  scoreValue: PropTypes.number,
  isReport: PropTypes.bool,
  isSkipped: PropTypes.bool,
  isModal: PropTypes.bool,
};

export { MealTableTitle };
