import { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import { useTabSynchronization } from './hooks/useTabSynchronization';
import { initUserInfo } from '../../utils/userInfoUtils';
import { useUnleashClient } from '../../clients/unleash';
import { loggedInUserDataStore } from '../../stores/loggedInUserDataStore';
import { useAuth0SingIn } from './hooks/useAuth0SingIn';
import { useAutoScaling } from './hooks/useAutoScaling';
import { usePrivateMode } from '../../features/private-mode';

const NgzInitUpdateUserInfo = observer(() => {
  const { loggedInUser } = loggedInUserDataStore;

  useUnleashClient(loggedInUser);

  useEffect(() => {
    initUserInfo();
  }, []);

  useTabSynchronization();
  useAuth0SingIn();
  useAutoScaling();
  usePrivateMode();

  return null;
});

angularize(NgzInitUpdateUserInfo, 'ngzInitUpdateUserInfo', angular.module('app'), {});

export { NgzInitUpdateUserInfo };
