import { useEffect } from 'react';

import { LOCAL_STORAGE_KEYS } from '../constants/LOCAL_STORAGE_KEYS';
import { privateModeStore } from '../stores/privateModeStore';
import '../styles/private-mode.css';

export const usePrivateMode = () => {
  const handlePrivateModeUpdate = (event: StorageEvent) => {
    const isValueChanged = event.newValue !== event.oldValue;
    const isPrivateMode = event.key === LOCAL_STORAGE_KEYS.PRIVATE_MODE && isValueChanged;

    if (isPrivateMode && event.newValue) {
      const value = JSON.parse(event.newValue);

      privateModeStore.handleModeChange(value);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handlePrivateModeUpdate);
    return () => {
      window.removeEventListener('storage', handlePrivateModeUpdate);
    };
  });

  useEffect(() => {
    privateModeStore.initPrivateMode();
  }, []);
};
