import React from 'react';

import classes from './TitleSection.module.scss';
import goHighLevelLogo from '../../../../shared/assets/image/goHighLevelLogo.webp';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { LbChip } from '../../../../shared/components/ui-components';

type TitleSectionProps = { contactId?: string | null };

const TitleSection = ({ contactId }: TitleSectionProps) => {
  const idInfo = contactId ? (
    <LbChip label={contactId} color="default" size="small" className="private-info" />
  ) : (
    <span>—</span>
  );

  return (
    <div className={classes.infoBox}>
      <img src={goHighLevelLogo} alt="GoHighLevel logo" />
      <TitleWithDescription
        title={
          <div className={classes.idTitleBox}>
            <span>GoHighLevel ID:</span>
            {idInfo}
          </div>
        }
        description="Verify if the platform contact is created. If not, click the Try again button"
      />
    </div>
  );
};

export { TitleSection };
