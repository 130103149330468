import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './InputView.module.scss';

type InputViewProps = {
  label?: ReactNode;
  value: ReactNode;
  endAdornment?: ReactNode;
  helperText?: ReactNode;
  valueFont?: 'input' | 'inherit';
  valueClassName?: string;
};

const InputView = ({
  label,
  value,
  endAdornment,
  helperText,
  valueFont = 'input',
  valueClassName,
}: InputViewProps) => {
  const valueClasses = classNames(
    classes.value,
    classes[`font-${valueFont}`],
    valueClassName,
  );

  return (
    <div className={classes.inputView}>
      {label && <div className={classes.label}>{label}</div>}
      <div className={valueClasses}>
        <span>{value}</span>
        {endAdornment && <span className={classes.adornment}>{endAdornment}</span>}
      </div>
      {helperText && <div className={classes.helperText}>{helperText}</div>}
    </div>
  );
};

export { InputView, InputViewProps };
