import React, { ReactNode } from 'react';

import classes from './SummaryInfo.module.scss';
import { LbCircularProgress } from '../../../../../../../shared/components/ui-components';
import { getIsNullish } from '../../../../../../../shared/utils/jsUtils';

type SummaryInfoProps = {
  title: ReactNode;
  total?: ReactNode;
  value?: ReactNode;
  progressValue?: number;
  dataTestId?: string;
};

const SummaryInfo = ({
  title,
  total,
  value,
  progressValue,
  dataTestId,
}: SummaryInfoProps) => {
  return (
    <div>
      <h6 className={classes.title}>
        {title}
        {total && <span className={classes.total}>&nbsp;{total}</span>}
      </h6>
      {!getIsNullish(progressValue) && (
        <div className={classes.valueBox}>
          <LbCircularProgress value={progressValue} size={24} />
          <span className={classes.value} data-test-id={dataTestId}>
            {progressValue}%
          </span>
        </div>
      )}
      {!getIsNullish(value) && (
        <p className={classes.value} data-test-id="total-meals">
          {value}
        </p>
      )}
    </div>
  );
};

export { SummaryInfo };
