import React, { useEffect, useState } from 'react';

import classes from './NutrientMetricsBlock.module.scss';
import { NutrientInfoCard } from './NutrientInfoCard/NutrientInfoCard';
import { NutrientMetricsWidget } from './NutrientMetricsWidget/NutrientMetricsWidget';
import { PerformanceMetricsParams } from '../../../../../interfaces/performance-metrics/PerformanceMetricsParams';
import { NutrientMetrics } from '../../../../../interfaces/performance-metrics/NutrientMetrics';
import { defaultNutrientMetrics } from './NutrientMetricsBlock.settings';
import { performanceMetricsService } from '../../../../../services/performanceMetricsService';

type NutrientMetricsBlockProps = {
  customerId: number;
  params: PerformanceMetricsParams;
};

const NutrientMetricsBlock = ({ customerId, params }: NutrientMetricsBlockProps) => {
  const [nutrientMetrics, setNutrientMetrics] =
    useState<NutrientMetrics>(defaultNutrientMetrics);

  const getNutrientMetrics = async () => {
    const data = await performanceMetricsService.getNutrientMetrics(customerId, params);

    setNutrientMetrics(data);
  };

  useEffect(() => {
    getNutrientMetrics();
  }, [customerId, params]);

  return (
    <div>
      <div className={classes.nutrientsCardRow}>
        <NutrientInfoCard
          nutrient="Proteins"
          mealsInfo={nutrientMetrics.proteins}
          dataTestId="proteins-card"
        />
        <NutrientInfoCard
          nutrient="Carbs"
          mealsInfo={nutrientMetrics.carbs}
          dataTestId="carbs-card"
        />
        <NutrientInfoCard
          nutrient="Fats"
          mealsInfo={nutrientMetrics.fats}
          dataTestId="fats-card"
        />
      </div>
      <NutrientMetricsWidget nutrientMetrics={nutrientMetrics} />
    </div>
  );
};

export { NutrientMetricsBlock };
