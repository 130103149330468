import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './ComplianceRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { CustomerComplianceHistory } from '../CustomerComplianceHistory/CustomerComplianceHistory';
import { CustomerContactLink } from '../../../../../../../shared/components/buttons';
import { ComplianceDataCells } from '../../../../../../components/ComplianceDataCells/ComplianceDataCells';
import { goTo } from '../../../../../../../shared/utils/angularUtils';
import {
  OpenCloseArrow,
  SeparatorLine,
} from '../../../../../../../shared/components/ui-components';
import { complianceStore } from '../../../../../../stores/complianceStore';

const ComplianceRow = observer(({ customerComplianceInfo }) => {
  const { showHistoryCustomerId, handleShowComplianceHistory } = complianceStore;

  const { firstName, lastName, userId, registrationDate, ...complianceInfo } =
    customerComplianceInfo;

  const isShowComplianceHistory = showHistoryCustomerId === userId;

  const rowClasses = classNames(classes.row, {
    [classes.historyIsOpen]: isShowComplianceHistory,
  });

  const handelGoToCustomerProfile = (event) => {
    event.stopPropagation();

    goTo(`/customer-profile/${userId}`);
  };

  return (
    <>
      <GridTableRow
        variant="row"
        onClick={() => handleShowComplianceHistory(userId)}
        className={rowClasses}
        dataTestId="compliance-row"
      >
        <GridTableCell justify="start" variant="title">
          <Button
            variant="link"
            disableRipple
            className="private-info"
            onClick={handelGoToCustomerProfile}
          >
            <span>{firstName}</span>
            &nbsp;
            <span>{lastName}</span>
          </Button>
        </GridTableCell>
        <ComplianceDataCells complianceInfo={complianceInfo} />
        <div className={classes.infoBox}>
          <CustomerContactLink customerId={userId} />
          <SeparatorLine />
          <OpenCloseArrow open={isShowComplianceHistory} />
        </div>
      </GridTableRow>
      {isShowComplianceHistory && (
        <CustomerComplianceHistory
          customerId={userId}
          firstName={firstName}
          lastName={lastName}
          customerRegistrationDate={registrationDate}
        />
      )}
    </>
  );
});

ComplianceRow.propTypes = {
  customerComplianceInfo: PropTypes.shape({
    userId: PropTypes.number,
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    breakfast: PropTypes.number,
    daily: PropTypes.number,
    dinner: PropTypes.number,
    lunch: PropTypes.number,
    snack1: PropTypes.number,
    snack2: PropTypes.number,
    snack3: PropTypes.number,
    sodiumGoal: PropTypes.number,
    totalSodium: PropTypes.number,
    totalWater: PropTypes.number,
    waterGoal: PropTypes.number,
  }),
};

export { ComplianceRow };
