import React, { useEffect, useMemo, useState } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import classes from './AvgPerformanceScoreWidget.module.scss';
import { ScoreLegend } from './ScoreLegend/ScoreLegend';
import { InfoBox } from '../../../../../../shared/components/ui-components';
import {
  chartOption,
  defaultPerformanceScore,
} from './AvgPerformanceScoreWidget.settings';
import { getChartData } from './utils/getChartData';
import { GeneralScoreInfo } from './GeneralScoreInfo/GeneralScoreInfo';
import { PerformanceMetricsParams } from '../../../../../interfaces/performance-metrics/PerformanceMetricsParams';
import { PerformanceScore } from '../../../../../interfaces/performance-metrics/PerformanceScore';
import { performanceMetricsService } from '../../../../../services/performanceMetricsService';

ChartJS.register(CategoryScale, LinearScale, BarElement);

type AvgPerformanceScoreWidgetProps = {
  customerId: number;
  params: PerformanceMetricsParams;
};

const AvgPerformanceScoreWidget = ({
  customerId,
  params,
}: AvgPerformanceScoreWidgetProps) => {
  const [performanceScore, setPerformanceScore] = useState<PerformanceScore>(
    defaultPerformanceScore,
  );
  const { avgComplianceScoreTotal, avgComplianceScoreTracked } = performanceScore;

  const chartData = useMemo(() => getChartData(performanceScore), [performanceScore]);

  const getPerformanceScore = async () => {
    const data = await performanceMetricsService.getPerformanceScore(customerId, params);

    setPerformanceScore(data);
  };

  useEffect(() => {
    getPerformanceScore();
  }, [customerId, params]);

  return (
    <InfoBox title="Average Performance Score" icon={<ScoreLegend />}>
      <div className={classes.box}>
        <div className={classes.generalInfoBox}>
          <GeneralScoreInfo
            title="Avg Compliance Score on All Meals"
            value={avgComplianceScoreTotal}
            color="secondary"
            dataTestId="avg-compliance-score-total"
          />
          <GeneralScoreInfo
            title="Avg Compliance Score on Meals Tracked"
            value={avgComplianceScoreTracked}
            color="primary"
            dataTestId="avg-compliance-score-tracked"
          />
        </div>
        <div className={classes.chartBox}>
          <Bar data={chartData} plugins={[ChartDataLabels]} options={chartOption} />
        </div>
      </div>
    </InfoBox>
  );
};

export { AvgPerformanceScoreWidget };
