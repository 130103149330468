import React from 'react';

import { useWatch, useFormContext } from 'react-hook-form';

import {
  InputBox,
  LbAutocomplete,
  LbInput,
  LbSelect,
} from '../../../shared/components/form-components';
import { BUSINESS_TYPE, businessTypeOptions } from '../../constants/businessType';
import {
  validateUrl,
  validateEmail,
  validatePhone,
  validateOneWord,
  validateZipCode,
} from '../../../shared/utils/reactHookFormUtils';
import { corporationTypeOptions } from '../../constants/corporationType';
import { states } from '../../../shared/constants/states';
import { EntityProfileInfo } from '../../interfaces/EntityProfileInfo';
import { availableEntityTypeOptions } from './EntityBusinessInfoInputs.settings';
import { validateEIN } from './utils/validateEIN';

type EntityBusinessInfoFromValue = {
  businessInfo: Omit<EntityProfileInfo, 'paymentFlowType'>;
};

type EntityBusinessInfoInputsProps = {
  mode: 'EDIT' | 'CREATE';
};

// If difficult manege paymentFlowType set it by default from props, update add on creation entity

const EntityBusinessInfoInputs = ({ mode }: EntityBusinessInfoInputsProps) => {
  const { control } = useFormContext<EntityBusinessInfoFromValue>();

  const selectedBusinessType = useWatch({ name: 'businessInfo.businessType', control });
  const isCorporateBusiness = selectedBusinessType === BUSINESS_TYPE.COMPANY;

  const generalInputProps = { control, size: 'small' } as const;
  const privateInfoProps = { InputProps: { className: 'private-info' } };

  const isCreate = mode === 'CREATE';

  return (
    <div>
      {isCreate && (
        <InputBox title="General Entity Setup">
          <LbSelect
            {...generalInputProps}
            name="businessInfo.entityType"
            label="Entity Type"
            options={availableEntityTypeOptions}
            rules={{ required: 'Entity Type is required' }}
          />
        </InputBox>
      )}
      <InputBox title="Entity Info">
        <LbInput
          {...generalInputProps}
          name="businessInfo.businessName"
          label="Entity Name"
          rules={{ required: 'Entity Name is Required' }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.ein"
          label="EIN"
          rules={{
            required: 'EIN is required',
            validate: { validateEIN },
          }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.businessEmail"
          label="Entity Email"
          rules={{
            required: 'Entity Email is required',
            validate: { validateEmail },
          }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.businessPhone"
          label="Entity Phone number"
          rules={{
            required: 'Entity Phone number is required',
            validate: { validatePhone },
          }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.businessLegalName"
          label="Name of Business"
          rules={{ required: 'Name of Business is required' }}
          {...privateInfoProps}
        />
        <LbSelect
          {...generalInputProps}
          name="businessInfo.businessType"
          label="Business Type"
          options={businessTypeOptions}
          rules={{
            required: 'Business Type is required',
          }}
          {...privateInfoProps}
        />
        {isCorporateBusiness && (
          <>
            <LbSelect
              {...generalInputProps}
              name="businessInfo.businessStructure.corporationType"
              label="Corporation Type"
              options={corporationTypeOptions}
              rules={{ required: 'Corporation Type is required' }}
              shouldUnregister
            />
            <LbInput
              {...generalInputProps}
              name="businessInfo.businessStructure.businessTradeNameDBA"
              label="DBA (Business Trade Name)"
              rules={{ required: 'DBA is required' }}
              shouldUnregister
            />
          </>
        )}
        <LbInput
          {...generalInputProps}
          name="businessInfo.url"
          label="Website"
          rules={{
            validate: { validateUrl },
          }}
        />
      </InputBox>
      <InputBox title="Address">
        <LbInput
          {...generalInputProps}
          name="businessInfo.address"
          label="Address"
          rules={{ required: 'Address is required' }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.city"
          label="City"
          rules={{ required: 'City is Required' }}
          {...privateInfoProps}
        />
        <LbAutocomplete
          {...generalInputProps}
          name="businessInfo.state"
          label="State"
          options={states}
          rules={{ required: 'State is required', deps: ['businessInfo.zip'] }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.zip"
          label="Zip Code"
          rules={{
            required: 'Zip Code is required',
            validate: async (zipCode, { businessInfo }) =>
              validateZipCode(zipCode, businessInfo.state),
          }}
          {...privateInfoProps}
        />
      </InputBox>
      <InputBox title="Info about owner">
        <LbInput
          {...generalInputProps}
          name="businessInfo.firstName"
          label="First Owner Name"
          rules={{
            required: 'First Owner Name is required',
            validate: { validateOneWord },
          }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.lastName"
          label="Last Owner Name"
          rules={{
            required: 'Last Owner Name is required',
            validate: { validateOneWord },
          }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.phoneNumber"
          label="Business Owner Phone"
          rules={{
            required: 'Business Owner Phone is required',
            validate: { validatePhone },
          }}
          {...privateInfoProps}
        />
        <LbInput
          {...generalInputProps}
          name="businessInfo.userEmail"
          label="Business Owner Email"
          rules={{
            required: 'Business Owner Email is required',
            validate: { validateEmail },
          }}
          {...privateInfoProps}
        />
      </InputBox>
    </div>
  );
};

export {
  EntityBusinessInfoInputs,
  EntityBusinessInfoFromValue,
  EntityBusinessInfoInputsProps,
};
