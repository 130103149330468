import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Divider } from '@mui/material';

import classes from './SubscriptionSettingsFrom.module.scss';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { SubscriptionSettingsFormValues } from '../../../interfaces/subscriptionSettings';
import { SubscriptionSettingsInput } from './SubscriptionSettingsInput/SubscriptionSettingsInput';
import { Mode } from '../../../../general-types';
import { InputView, LbSwitch } from '../../../../shared/components/form-components';
import { SaasTaxDescription } from './SaasTaxDescription/SaasTaxDescription';

type SubscriptionSettingsFromProps = {
  mode: Mode;
  isSaasTaxApplied?: boolean;
};

const SubscriptionSettingsFrom = ({
  mode,
  isSaasTaxApplied,
}: SubscriptionSettingsFromProps) => {
  const { control, watch } = useFormContext<SubscriptionSettingsFormValues>();

  const generalInputProps = { control, mode } as const;
  const generalPriceInputProps = {
    prefix: '$',
    decimalScale: 2,
    allowNegative: false,
  } as const;

  const isApplicationTaxEnabled = watch('subscriptionSettings.applicationTaxEnabled');

  return (
    <div className={classes.box}>
      <div className={classes.group}>
        <TitleWithDescription
          title="LifeBase Fees"
          description="Users will be charged when purchasing a subscription"
          titleFont="body-14-bold"
        />
        <div className={classes.inputColumn}>
          <SubscriptionSettingsInput
            {...generalInputProps}
            {...generalPriceInputProps}
            name="subscriptionSettings.onboardingFees"
            label="One-time Fee"
            rules={{
              required: 'One-time Fee is required',
            }}
          />
          <SubscriptionSettingsInput
            {...generalInputProps}
            {...generalPriceInputProps}
            name="subscriptionSettings.initialPlanFees"
            label="Initial Plan Fees"
            rules={{
              required: 'Initial Plan Fees is required',
            }}
          />
          <SubscriptionSettingsInput
            {...generalInputProps}
            {...generalPriceInputProps}
            name="subscriptionSettings.renewalFees"
            label="Monthly Renewal Fees"
            rules={{
              required: 'Monthly Renewal Fees is required',
            }}
          />
        </div>
      </div>
      <Divider />
      <div className={classes.group}>
        {mode === 'EDIT' ? (
          <LbSwitch
            control={control}
            name="subscriptionSettings.applicationTaxEnabled"
            label={<SaasTaxDescription />}
            align="start"
          />
        ) : (
          <SaasTaxDescription showChip isSaasTaxApplied={isSaasTaxApplied} />
        )}
        <div className={classes.inputColumn}>
          {isApplicationTaxEnabled && (
            <SubscriptionSettingsInput
              {...generalInputProps}
              name="subscriptionSettings.applicationTaxRatePercent"
              label="Sales tax (SaaS incl)"
              rules={{
                required: 'Sales Tax is required',
                min: {
                  value: 1,
                  message: 'Sales Tax must be greater than 0',
                },
                max: {
                  value: 100,
                  message: 'Sales Tax must be less than 100',
                },
              }}
              allowNegative={false}
              suffix="%"
            />
          )}
          {!isApplicationTaxEnabled && mode === 'VIEW' && (
            <InputView
              label="Sales tax (SaaS incl)"
              value="Not set"
              valueClassName="private-info"
            />
          )}
        </div>
      </div>
      <Divider />
      <div className={classes.group}>
        <TitleWithDescription
          title="Minimum Entity Fee"
          description="The first year of software has no monthly fee (other than user fees).  
          After 1 year, LifeBase charges the total minimum monthly fee."
          titleFont="body-14-bold"
        />
        <div className={classes.inputColumn}>
          <SubscriptionSettingsInput
            {...generalInputProps}
            {...generalPriceInputProps}
            name="subscriptionSettings.minimalFeesGoal"
            label="Minimum Entity Fee"
            rules={{
              required: 'Minimum Entity Fee is required',
            }}
            helperText="Billing Period is 1 Month"
          />
        </div>
      </div>
    </div>
  );
};

export { SubscriptionSettingsFrom, SubscriptionSettingsFromProps };
