import React from 'react';

import { Switch } from '@mui/material';
import classNames from 'classnames';

import classes from './SubscriptionRow.module.scss';
import {
  GridTableRow,
  GridStickyGroup,
  GridTableCell,
} from '../../../../shared/components/table-components';
import { EntitySubscriptionInfo } from '../../../interfaces/entitySubscription';
import { SubscriptionTypeChip } from '../../SubscriptionTypeChip/SubscriptionTypeChip';
import { PriceCell } from './PriceCell/PriceCell';
import {
  PriceCellWithDescription,
  DescriptionOption,
} from './PriceCellWithDescription/PriceCellWithDescription';
import { DisplayToUserStatusCell } from './DisplayToUserStatusCell/DisplayToUserStatusCell';
import {
  ChangeActivationStatus,
  ChangeDisplayToUserStatus,
} from '../EntitySubscriptionList.settings';

type SubscriptionRowProps = {
  subscriptionInfo: EntitySubscriptionInfo;
  changeActivationStatus: ChangeActivationStatus;
  changeDisplayToUserStatus: ChangeDisplayToUserStatus;
  disabledEdit?: boolean;
};

const SubscriptionRow = ({
  subscriptionInfo,
  changeActivationStatus,
  changeDisplayToUserStatus,
  disabledEdit,
}: SubscriptionRowProps) => {
  const {
    id,
    name,
    active,
    subscriptionType,
    initialPlanDuration,
    initialPlanPrice,
    totalInitialPlanFees,
    totalInitialPlanPrice,
    initialPlanStripeFees,
    initialPlanFullPeriodFees,
    initialPlanOneTimeFee,
    initialApplicationTax,
    monthlyRenewalPrice,
    monthlyRenewalFees,
    monthlyApplicationTax,
    totalMonthlyRenewalFees,
    totalMonthlyRenewalPrice,
    displayToUser,
    monthlyRenewalStripeFees,
  } = subscriptionInfo;

  const initialPlanFeesDescription: DescriptionOption[] = [
    { label: 'Initial Plan Fees', value: initialPlanFullPeriodFees },
    { label: 'One-Time Fee', value: initialPlanOneTimeFee },
    { label: 'SaaS Tax', value: initialApplicationTax },
    { label: 'Stripe Fees', value: initialPlanStripeFees },
  ];

  const monthlyRenewalFeesDescription: DescriptionOption[] = [
    { label: 'Monthly Renewal Fees', value: monthlyRenewalFees },
    { label: 'SaaS Tax', value: monthlyApplicationTax },
    { label: 'Stripe Fees', value: monthlyRenewalStripeFees },
  ];

  return (
    <GridTableRow variant="subRow" className={classNames(classes.row, 'private-info')}>
      <GridStickyGroup>
        <GridTableCell justify="center">
          <Switch
            checked={active}
            onChange={(event, checked) => changeActivationStatus(id, checked)}
            disabled={disabledEdit}
          />
        </GridTableCell>
        <GridTableCell>{name}</GridTableCell>
      </GridStickyGroup>
      <GridTableCell>
        <SubscriptionTypeChip subscriptionType={subscriptionType} />
      </GridTableCell>
      <GridTableCell>{initialPlanDuration || '—'}</GridTableCell>
      <PriceCell price={initialPlanPrice} />
      <PriceCell price={totalInitialPlanPrice} />
      <PriceCellWithDescription
        price={totalInitialPlanFees}
        description={initialPlanFeesDescription}
      />
      <PriceCell price={monthlyRenewalPrice} />
      <PriceCell price={totalMonthlyRenewalPrice} />
      <PriceCellWithDescription
        price={totalMonthlyRenewalFees}
        description={monthlyRenewalFeesDescription}
      />
      <DisplayToUserStatusCell
        active={active}
        displayToUser={displayToUser}
        subscriptionId={id}
        onDisplayToUserStatusChange={changeDisplayToUserStatus}
        disabled={disabledEdit}
      />
    </GridTableRow>
  );
};

export { SubscriptionRow, SubscriptionRowProps };
