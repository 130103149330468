import React from 'react';

import { Path, useController, useFormContext } from 'react-hook-form';

import classes from './DisabledSelectInfo.module.scss';
import { Option } from '../../../../../general-types';
import { EntityInbodyDataFormValue } from '../../EntityInbodyDataConfig.settings';

type DisabledSelectInfoProps = {
  name: Path<EntityInbodyDataFormValue>;
  label: string;
  options: Option[];
};
// need use Controller because of using shouldUnregister in useForm hook (delete value if it not used in form)
const DisabledSelectInfo = ({ label, name, options }: DisabledSelectInfoProps) => {
  const { control } = useFormContext<EntityInbodyDataFormValue>();
  const { field } = useController({ name, control });
  const selectedOption = options.find((option) => option.value === field.value);

  return (
    <div className={classes.textInfo}>
      <span className={classes.label}>{label}</span>
      <span className="private-info">{selectedOption?.label}</span>
    </div>
  );
};

export { DisabledSelectInfo };
