import React from 'react';
import PropTypes from 'prop-types';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import { SystemProviders } from '../../../../../shared/providers';
import { PageTitle } from '../../../../../shared/components/ui-components';
import { CustomerComplianceHistoryTable } from './CustomerComplianceHistoryTable/CustomerComplianceHistoryTable';
import { DataRangeFilter } from './DataRangeFilter/DataRangeFilter';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { useCustomerComplianceHistory } from '../../../../hooks/compliance-hooks';
import { ConsumptionBetweenWidget } from '../../../../components/consumption';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { dateFormat } from '../../../../../shared/constants/apiDateFormat';

const NgzCustomerComplianceHistoryTab = ({
  customerFirstName,
  customerLastName,
  registrationDate,
}) => {
  const customerId = +getRouteParam('id');
  const customerRegistrationDate = getFormattedDate(
    registrationDate,
    dateFormat,
    'MM.DD.YYYY',
  );

  const { formMethods, handleSubmit, complianceFields, consumptionFields } =
    useCustomerComplianceHistory({
      customerId,
      customerRegistrationDate,
    });
  const { control } = formMethods;

  const { consumptionInfo, isShowConsumptionWidget, toggleShowConsumptionWidget } =
    consumptionFields;

  return (
    <SystemProviders>
      <Container>
        <PageTitle title="Compliance">
          <DataRangeFilter
            control={control}
            onSubmit={handleSubmit}
            isShowConsumptionWidget={isShowConsumptionWidget}
            toggleShowConsumptionWidget={toggleShowConsumptionWidget}
            customerRegistrationDate={customerRegistrationDate}
          />
        </PageTitle>
        <CustomerComplianceHistoryTable {...complianceFields} customerId={customerId} />
        {isShowConsumptionWidget && (
          <ConsumptionBetweenWidget
            consumptionInfo={consumptionInfo}
            firstName={customerFirstName}
            lastName={customerLastName}
            onClose={toggleShowConsumptionWidget}
          />
        )}
      </Container>
    </SystemProviders>
  );
};

NgzCustomerComplianceHistoryTab.propTypes = {
  customerFirstName: PropTypes.string,
  customerLastName: PropTypes.string,
  registrationDate: PropTypes.string,
};

angularize(
  NgzCustomerComplianceHistoryTab,
  'ngzCustomerComplianceHistoryTab',
  angular.module('app'),
  {
    customerFirstName: '<',
    customerLastName: '<',
    registrationDate: '<',
  },
);
