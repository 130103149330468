import React from 'react';

import classes from './AvgMealRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../../shared/components/table-components';
import { NutrientMetricsForMeal } from '../../../../../../../interfaces/performance-metrics/NutrientMetrics';

type MealRowProps = {
  avgInfo: NutrientMetricsForMeal;
};

const AvgMealRow = ({ avgInfo }: MealRowProps) => {
  const { fiber, sodium, sugar, veggies, calories } = avgInfo;

  return (
    <GridTableRow
      variant="subRow"
      className={classes.row}
      data-test-id="nutrients-avg-meals"
    >
      <GridTableCell variant="title">Average on Meals Tracked</GridTableCell>
      <GridTableCell className={classes.fiberCell}>
        {fiber ? (
          <>
            <span className={classes.value}>{fiber.avgPerMeal}</span>
            <span className={classes.description}>{fiber.completedMealsPercent}%</span>
          </>
        ) : (
          '—'
        )}
      </GridTableCell>
      <GridTableCell>{sodium ? sodium.avgPerMeal : '—'}</GridTableCell>
      <GridTableCell>{sugar ? sugar.avgPerMeal : '—'}</GridTableCell>
      <GridTableCell>{veggies ? veggies.avgPerMeal : '—'}</GridTableCell>
      <GridTableCell>{calories ? calories.avgPerMeal : '—'}</GridTableCell>
    </GridTableRow>
  );
};

export { AvgMealRow };
