'use strict';

angular
    .module('app')
    .controller('NotesController', NotesController);

NotesController.$inject = [
    '$location',
    '$routeParams',
    'utilService',
    'CoachNotesService',
    'ModalService',
    'CustomerService'
];

function NotesController($location, $routeParams, utilService, CoachNotesService, ModalService, CustomerService) {

    var vm = this;

    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    vm.newNote = {users: []};
    vm.spinner = {active: true};
    vm.page = 0;
    vm.limit = 5;
    vm.notSuper = 'SUPERUSER' !==vm.loggedInUser.role;

    vm.getCoachNotesByUser = getCoachNotesByUser;
    vm.processCoachNotes = processCoachNotes;
    vm.displayDeleteNoteDialog = displayDeleteNoteDialog;
    vm.removeUserNote = removeUserNote;
    vm.changeUserTags = changeUserTags;
    vm.saveNewNote = saveNewNote;
    vm.editNote = editNote;
    vm.getUsersAsTagsList = getUsersAsTagsList;
    vm.loadUserTags = loadUserTags;
    vm.goToPrevPage = goToPrevPage;
    vm.goToNextPage = goToNextPage;
    vm.getNoteByUserId = getNoteByUserId;
    vm.getCustomerDetails = getCustomerDetails;
    vm.getAllCoachNotes = getAllCoachNotes;
    vm.coachOrSuper = coachOrSuper;

    vm.getCoachNotesByUser();
    vm.getUsersAsTagsList();

    function getCoachNotesByUser() {
        if ($location.path().indexOf('/coach-profile') > -1 || $location.path().indexOf('/coach-notes') > -1) {
            vm.getAllCoachNotes();
        } else {
            vm.getCustomerDetails();
            vm.getNoteByUserId();
        }
    }

    function getAllCoachNotes() {
        vm.spinner.active = true;
        CoachNotesService.getAllCoachNotes({page: vm.page, limit: vm.limit}).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.processCoachNotes(res.data);
            }
        });
    }

    function getCustomerDetails() {
        CustomerService.getCustomerDetails(vm.loggedInUser.id, $routeParams.id).then(function (res) {
            // name used for filtering users, firstName and lastName for displaying
            const userTagInfo = {
                id: res.data.id,
                email: res.data.email,
                name: res.data.firstName + ' ' + res.data.lastName,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                img: res.data.avatar
            };

            if (res.status === 200) {
                vm.newNote.users = [userTagInfo];
                if (vm.loggedInUser.role === 'SUPERUSER') {
                    vm.userTagsList = [userTagInfo];
                }
            }
        });
    }

    function coachOrSuper(callCoach, callSuper) {
        if (vm.loggedInUser.role === 'AGENT') {
            callCoach();
        }
        if (vm.loggedInUser.role === 'SUPERUSER') {
            callSuper();
        }
    }

    function getNoteByUserId() {
        vm.spinner.active = true;

        CoachNotesService.getNoteByUserId($routeParams.id, {page: vm.page, limit: vm.limit}).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.processCoachNotes(res.data);
            }
        });
    }

    function processNote(currentNote) {
        var date = currentNote.created.split('/');
        currentNote.dateDay = date[1];
        currentNote.dateMonth = vm.monthNames[date[0] - 1];
        currentNote.isEdited = false;

        for (var j = 0; j < currentNote.users.length; j++) {
            var user = currentNote.users[j];
            user.name = user.firstName + ' ' + user.lastName;
        }
    }

    function processCoachNotes(notes) {
        vm.coachNotes = notes;

        for (var i = 0; i < vm.coachNotes.notes.length; i++) {
            processNote(vm.coachNotes.notes[i]);
        }
    }

    function displayDeleteNoteDialog(noteId) {
        ModalService.confirm('Are you sure you want to delete a note?', vm.removeUserNote, noteId, null, 'Cancel', 'Delete')
    }

    function removeUserNote(noteId) {
        CoachNotesService.removeNote(noteId).then(function (res) {
            if (res.status === 200) {
                vm.getCoachNotesByUser();
            }
        });
    }

    function changeUserTags(note) {
        if (note.users) {
            note.users = note.users.filter(function (item) {
                if (item.id) {
                    return item.id;
                }
            });
        } else {
            note.users = [];
        }
    }

    function saveNewNote() {
        var note = angular.copy(vm.newNote);
        note.users = note.users.map(function (user) {
            return {id: user.id};
        });
        var callback = function (res) {
            if (res.status === 200) {
                vm.newNote = {users: []};
                vm.getCoachNotesByUser();
            }
        };

        vm.coachOrSuper(function() {
            CoachNotesService.createCoachNote(note).then(callback);
        }, function() {
            CoachNotesService.createCoachNoteAsSuper(note).then(callback);
        });
    }

    function editNote(note) {
        var updatedNote = angular.copy(note);
        delete updatedNote.dateDay;
        delete updatedNote.dateMonth;
        delete updatedNote.isEdited;
        updatedNote.users = updatedNote.users.map(function (user) {
            return {id: user.id};
        });

        CoachNotesService.editNote(updatedNote.id, updatedNote).then(function (res) {
            if (res.status === 200) {
                note.isEdited = false;
                vm.getCoachNotesByUser();
            }
        });
    }

    function getUsersAsTagsList() {
        if (vm.loggedInUser.role === 'SUPERUSER') {
            return;
        }
        CoachNotesService.getUsersByTheirNames().then(function (res) {
            if (res.status === 200) {
                vm.userTagsList = res.data.names;
            }
        });
    }

    function loadUserTags(query) {
        return vm.userTagsList ? vm.userTagsList.filter(function (userName) {
            return userName.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        }) : null
    }

    function goToPrevPage() {
        if (vm.coachNotes.prev) {
            vm.page--;
            vm.getCoachNotesByUser();
        }
    }

    function goToNextPage() {
        if (vm.coachNotes.next) {
            vm.page++;
            vm.getCoachNotesByUser();
        }
    }

}

